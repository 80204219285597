/* Main container for the button */
.help-button-container {
    position: relative; /* No absolute positioning needed */
  }
  
  .help-button {
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease, transform 0.2s ease;
  }
  
  .help-button:hover {
    background-color: #0366d6;
    transform: scale(1.05);
  }
  
  /* Menu that appears when the help button is clicked */
  .help-menu {
    position: absolute;
    width: 200px;
    top: 60px; /* Position it below the button */
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 9998;
  }
  
  /* Style for each menu item */
  .help-menu-item {
    background-color: white;
    border: none;
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: block;
    color: black;
    transition: background-color 0.2s ease;
  }
  
  .help-menu-item:hover {
    background-color: #f1f1f1;
  }
  