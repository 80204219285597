.header {
    top: 0;
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem; /* 64px, similar to h-16 in Tailwind */
    padding: 0 1rem; /* Adds some spacing around the content */
    border-bottom: 1px solid #e5e7eb; /* Light gray border for separation */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for better visibility */
  }
    
.header-title {
    font-size: 1.25rem; /* 20px, similar to text-xl in Tailwind */
}

.header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.profile-button {
    margin-right: 8px;
}

.layout-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

.content {
    flex: 1;
    overflow-y: auto; /* Make this section scrollable */
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Montserrat Fallback';src: local("Arial");ascent-override: 85.79%;descent-override: 22.25%;line-gap-override: 0.00%;size-adjust: 112.83%
}.__className_4bc053 {font-family: 'Montserrat', 'Montserrat Fallback';font-style: normal
}


/* Main container for the button */
.help-button-container {
    position: relative; /* No absolute positioning needed */
  }
  
  .help-button {
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease, transform 0.2s ease;
  }
  
  .help-button:hover {
    background-color: #0366d6;
    transform: scale(1.05);
  }
  
  /* Menu that appears when the help button is clicked */
  .help-menu {
    position: absolute;
    width: 200px;
    top: 60px; /* Position it below the button */
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 9998;
  }
  
  /* Style for each menu item */
  .help-menu-item {
    background-color: white;
    border: none;
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: block;
    color: black;
    transition: background-color 0.2s ease;
  }
  
  .help-menu-item:hover {
    background-color: #f1f1f1;
  }
  
